body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.primary {
  background-color: #58046c;
  border-color: #58046c;
}

.primary:hover {
  background-color: #390347;
  border-color: #390347;
}

.btn-primary {
  --bs-btn-active-bg: #4E0462;
  --bs-btn-active-border-color:#4E0462;
}

h1 {
  font-size: 45pt;
}

.education {
  border: solid;
  border-color: #390347;
  width: 65%;
  max-height: 20%;
  margin: 10pt;
  padding: 10pt;
  background-color: #58046c;
  color: white;
  box-shadow: 5px 8px 10px #390347;
}

@media (max-width: 1000px) {
  .education {
    width: 95%;
    /* Remove margin-left and margin-right */
    /* margin-left: 0;
    margin-right: 0; */
    margin: 10pt auto; /* Center horizontally using auto margins */
  }

  .project {
    width: 95%;
    height: auto;
    /* Remove margin-left and margin-right */
    /* margin-left: 0;
    margin-right: 0; */
    margin: 10pt auto; /* Center horizontally using auto margins */
  }
}

.education h4{
  color: #f3d0d2;
}

.project {
  width: auto;
  height: 30%;
  margin: 10pt;
  padding: 10pt;
  background-color: #f3d0d2;
  color: black;
  box-shadow: 5px 8px 10px darkgray;
}

.project h2 {
  color:#4E0462;
}

.project-link {
  text-decoration: none;
  color: black;
}

.column-list {
  column-gap: 20px; /* Adjust the gap between columns as needed */
}

@media (min-width: 768px) {
  .column-list {
    column-count: 2; /* Show 2 columns on screens wider than 768px */
  }
}

@media (min-width: 992px) {
  .column-list {
    column-count: 3; /* Show 3 columns on screens wider than 992px */
  }
}
/* Add this CSS to your stylesheet */
.project-card {
  height: 100%; /* Set the card height to 100% */
  display: flex;
  flex-direction: column;
}
.contact {
  margin-top: 2rem;
}

.contact h2 {
  text-align: center;
  margin-bottom: 20px;
}
.accordion h2 {
  text-align: center;
  margin-bottom: 20px;
}

.contact-form-container {
  max-width: 60.75rem;
  margin: 0 auto;
  padding: 0.938rem;
  border-radius: 5px;
  box-shadow: 0 3px 10px darkgray;
}

.contact-form-container label {
  line-height: 2.7em;
  font-weight: var(--bold-font);
  color: #4E0462;
}

.contact-form-container textarea {
  min-height: 6.25rem;
  font-size: 14px;
}

.contact-form-container .input-field {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  border: none;
  border: 2px solid #4E0462;
  font-size: 0.875rem;
  outline: none;
}

.input-field::placeholder {
  padding: 0.5rem;
  color:  #4E0462;
}

.submit-btn {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  background-color: #fff;
  border: 2px solid #4E0462;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: var(--bold-font);
  transition: var(--transition);
}

.submit-btn:hover {
  background-color: #4E0462;
  border: 2px solid #4E0462;
  cursor: pointer;
  color:#fff;
}


.accordion {
  width: 60%;
  margin: 2rem auto;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background-color: #390347;
  color: white;
}

.job-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color:#390347
}

.accordion-title:hover {
  background-color: #4E0462;
}

.accordion-title,
.accordion-content {
  padding: 1rem;
}

.accordion-content {
  background-color: #f3d0d2;
}

.awards {
  width: 60%;
  margin: 0 auto; /* Center the awards section */
}





.award-more {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: auto;
  margin-left: auto;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 1.5em;
  color: #4E0462;
}

.bg-peri {
  background-color: #abb8f4;
}

h1 {
  color:#390347;
}

.socials {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 1%;
  bottom: 5%;
}

.socicon {
  width: 2rem;
  height: 2rem;
}



@media screen and (max-width: 1000px) {

  .accordion {
    width: 100%;
  }
  .job-title {
    display: flex;
    flex-direction: column;
    color:#390347
  }
  .awards {
    width: 100%;
  }
  .socials {
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: center;
  }

}
@media (min-width: 768px) {
.navbar-expand-md .navbar-collapse  {
justify-content: flex-end;
}
}
